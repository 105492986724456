<template>
  <div class="main_container">
    <!-- Ligne n°1 -->
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Détails Rédevance Péage</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Rapport sur les redevances de péage
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- Fin ligne n°1 -->
    <!-- Ligne n°2 -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="Rapport_patente_vignette"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="1000px"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"> 
      <section slot="pdf-content">
        
      </section> 
    </vue-html2pdf>
    <!-- END of Vue-html2pdf  -->
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez la gare</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="gare"
                    @change="changeGare(gare)">
              <option v-for="(gare,garekey) in listGare"
                      :key="garekey"
                      :value="gare">{{gare}}</option>
              <!-- <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option> -->
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez un axe</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="axe"
                    @change="changeAxe(axe)">
              <option v-for="(axe,axekey) in listAxe"
                      :key="axekey"
                      :value="axe">{{axe}}</option>
              <!-- <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option> -->
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-5"
         v-if="yearData == null">
      <div class="col-md-10 mx-auto">
        <h4 class="text-center">
          Veuillez sélectionner une période contenant des données.
        </h4>
      </div>
    </div>
    <div class="row mt-4 mb-5"
         v-if="yearData != null">
      <div class="text-center mb-5 mt-3"
           :class="width"
           style="height:300px;"
           v-if="gareData != null">
        <h5>Recettes des gares de peage par mois</h5>
        <LineGenerator :chartdata="chartDataLineChart "
                       :height="270"></LineGenerator>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && gare!='TOUTES'">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          Le mois le plus rentable de la gare de {{ gare }} de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMax.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.pourcentage }}</strong> % de la recette annuelle ({{ monthTotal.toLocaleString() }} FCFA).
        </p>
        <p>
          Le mois le moins rentable de la gare de {{ gare }} de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMin.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.pourcentage }}</strong> % de la recette annuelle ({{ monthTotal.toLocaleString() }} FCFA).
        </p>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && gare=='TOUTES'">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          Le mois le plus rentable de {{ gare }} les gares de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMax.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.pourcentage }}</strong> % de la recette annuelle ({{ totalAnnee.toLocaleString() }} FCFA).
        </p>
        <p>
          Le mois le moins rentable de la gare de {{ gare }} de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMin.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.pourcentage }}</strong> % de la recette annuelle ({{ totalAnnee.toLocaleString() }} FCFA).
        </p>
      </div>
    </div>
    <div class="row mt-5"
         style="background-color: #b1dbe0;min-height: 450px;"
         v-if="yearData != null">
      <div class="text-center mb-5 mt-3"
           :class="width"
           style="height:300px;"
           v-if="yearData != null">
        <h5>Recettes des gares de peage</h5>    
        <Pie :chartdata="chartDataPieChart"
             :height="270"></Pie>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && (gare=='TOUTES' || gare=='CONSOLIDEE')">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La gare la plus rentable de l'année {{ annee_debut.split("-")[0] }} est la gare de 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMax.gare }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMax.montant }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMax.pourcentage }}</strong> % de la recette annuelle ({{ total.recette.toLocaleString() }} FCFA).
        </p>
        <p>
          La gare la moins rentable de l'année {{ annee_debut.split("-")[0] }} est la gare de 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMin.gare }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMin.montant }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMin.pourcentage }}</strong> % de la recette annuelle ({{ total.recette.toLocaleString() }} FCFA).
        </p>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && gare!='TOUTES'&& gare!='CONSOLIDEE'">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La gare  
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.gare }}</strong> 
          se place à la  
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.rang }}<sup>{{ garePosition.rang!=1?"ème":"ère" }}</sup></strong> 
          place pour cette année {{ annee_debut.split("-")[0] }} avec
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.recette.toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.pourcentage }}</strong> % de la recette annuelle ({{ total.recette.toLocaleString() }} FCFA).
        </p>
      </div>
    </div>
    <div class="row mt-5"
         v-if="yearData != null">
      <div class="text-center mb-5 mt-3 col-md-7"
           v-if="gare!='TOUTES' && gare!=null && gare!=''"
           style="height:300px;">
        <h5>Recettes des gares de péage par nature</h5>    
        <BarChart :chartdata="chartDataBarChart"
                  :height="270"
                  v-if="dataLoaded"></BarChart>
      </div>
      <div class="text-center mb-5 mt-3 col-md-7"
           v-if="gare=='TOUTES' || axe!=null && axe!=''"
           style="height:300px;">
        <h5>Recettes des gares de péage par nature</h5>    
        <BarChart :chartdata="chartDataBarChart2"
                  :height="270"
                  :options="optionBarChart"
                  v-if="heavyDataLoaded"></BarChart>
      </div>
      <div class="col-md-5 mb-5 mt-3"
           v-if="gare!='TOUTES' && gare!=null && gare!=''">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La nature de recette la plus rentable de la gare <strong style="color: #0097a9;font-weight: bold;">{{ gare }}</strong> 
          est la nature 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.pourcentage == "paiement_carte"?"paiements par carte":"paiements comptants" }}</strong>
          avec <strong style="color: #0097a9;font-weight: bold;">{{ Number(totalNatureRecette[totalNatureRecette.pourcentage]).toLocaleString() }}</strong> FCFA de recette annuelle contre
          <strong style="color: #0097a9;font-weight: bold;">
            {{ Number(totalNatureRecette[totalNatureRecette.pourcentage == "paiement_carte"?"paiement_comptant":"paiement_carte"]).toLocaleString() }}
          </strong> FCFA pour les
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.pourcentage == "paiement_carte"?"paiements comptants":"paiements par carte" }}</strong>. <br>
          Cette nature s'illustre avec une valeur maximale de 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMax.montant.toLocaleString() }}</strong> FCFA en 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMax.mois }}</strong> et une valeur minimale de 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMin.montant.toLocaleString() }}</strong> FCFA en 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMin.mois }}</strong>
        </p>
      </div>
      <div class="col-md-5 mb-5 mt-3"
           v-if="gare=='TOUTES' || axe!=null && axe!=''">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La gare qui rapporte le plus 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong> 
          au paiement au comptant est la gare <strong style="color: #0097a9;font-weight: bold;">{{ paiement_comptant.max.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(paiement_comptant.max.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
          La gare qui rapporte le moins 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          au paiement au comptant est la gare <strong style="color: #0097a9;font-weight: bold;">{{ paiement_comptant.min.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(paiement_comptant.min.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
        </p>
        <p>
          La gare qui rapporte le plus
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          au paiement par carte est la gare <strong style="color: #0097a9;font-weight: bold;">{{ paiement_carte.max.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(paiement_carte.max.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
          La gare qui rapporte le moins 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          au paiement par carte est la gare <strong style="color: #0097a9;font-weight: bold;">{{ paiement_carte.min.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(paiement_carte.min.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
        </p>
      </div>
    </div>
  </div>
</template>
<style>
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
.btn-info,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search, .table-blue{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import VueHtml2pdf from "vue-html2pdf"
import BarChart from "@/components/shared/BarChart"
import Pie from "../../shared/PieChart"
import LineGenerator from "../../shared/LineChart"
export default {
  name: "RapportRedevancePeage",
  components: {
    VueHtml2pdf,
    BarChart,
    Pie,
    LineGenerator,
  },
  
  data: () => ({
    heavyDataLoaded:false,
    dataLoaded:false,
    chartDataLineChart: {
      labels: [],
      datasets: [
        {
          label: "Recette péage ",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },
    optionLineChart:{
      legend: {
        labels: {
          fontColor: '#ffffff'
        }
      }
    },
    optionBarChart:{
      scales:{
        x:{
          stacked:true,
        },
        y:{
          stacked:true,
        },
      }
    },
    chartDataPieChart: {
      labels: [],
      datasets: [
        {
          label: "Recette péage par gare (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },
    chartDataBarChart: {labels: [], datasets: []},
    chartDataBarChart2: {labels: [], datasets: []},
    width: "col-md-6",
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",  
    },
    color:"",
    yearData: null,
    month: [
      { mois: "Janv.", total: 0 },
      { mois: "Fev.", total: 0 },
      { mois: "Mars", total: 0 },
      { mois: "Avr.", total: 0 },
      { mois: "Mai.", total: 0 },
      { mois: "Juin", total: 0 },
      { mois: "Juil.", total: 0 },
      { mois: "Août.", total: 0 },
      { mois: "Sept.", total: 0 },
      { mois: "Oct.", total: 0 },
      { mois: "Nov.", total: 0 },
      { mois: "Dec.", total: 0 },
    ],
    monthTotal: 0,
    gareData: null,
    gare:"ATTINGUE",
    axe:"",
    listAxe:[
      "Recettes de péage autoroute du nord",
      "Recettes de péage de Thomasset et de Moapé",
      "Recettes de péage autoroute du sud",
      "TOUS"
    ],
    listGare:[],
    total: {
      recette:0,
      direct:0,
      carte:0,
    },
    moisMax:{
      mois:"",
      montant:0,
      pourcentage:""
    },
    moisMin:{
      mois:"",
      montant:0,
      pourcentage:""
    },
    gareMax:{
      gare:"",
      montant:0,
      pourcentage:""
    },
    gareMin:{
      gare:"",
      montant:0,
      pourcentage:""
    },
    paiement_carte:{
      max:{
        montant:0,
        gare:0,
      },
      min:{
        montant:0,
        gare:0,
      },
    },
    paiement_comptant:{
      max:{
        montant:0,
        gare:0,
      },
      min:{
        montant:0,
        gare:0,
      },
    },
    totalAnnee:0,
    totalNatureRecette:{
      paiement_carte:0,
      paiement_comptant:0,
      moisMax:{montant:0,mois:""},
      moisMin:{montant:0,mois:""},
      pourcentage:"",
    },
    gareRanking:[],
    garePosition:"",
    tableauCouleurs: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"],
  }),
  created() {
    this.RecettePeage(this.year)
    this.RecettePeageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, gare: this.gare })

    if (window.innerWidth > 1400) {
      this.width = "col-md-4 mx-auto"
    }
  },
  watch: {
    recettePeageG() {
      if (this.recettePeageG["tableau"]) {
        if (this.gare!=null && this.gare!="") {
          if (this.gare !="TOUTES") {
            this.gareData=null
            this.gareData = this.recettePeageG["tableau"][0]
            this.monthTotal = 0
            // initialisation de l'objet d'analyse des recettes par nature
            this.totalNatureRecette={
              paiement_carte:0,
              paiement_comptant:0,
              moisMax:{montant:0,mois:""},
              moisMin:{montant:0,mois:""},
              pourcentage:"",
            }
            this.chartDataBarChart = {
              labels: [],
              datasets: [
                {
                  label: "Paiement comptant",
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                },
                {
                  label: "Paiement par carte",
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                }
              ]
            }
            // console.log(this.chartDataBarChart) 
            for (let index = 0; index < this.month.length; index++) {
              this.month[index].total =
                this.gareData.paiement_comptant[index] +
                this.gareData.paiement_carte[index]

              this.monthTotal += this.month[index].total
              // Remplissage des données du lineChart
              this.chartDataLineChart.labels.push(this.month[index].mois)
              this.chartDataLineChart.datasets[0].data.push(this.month[index].total)
              // Calcul des totaux par nature de recette
              this.totalNatureRecette.paiement_comptant += this.gareData.paiement_comptant[index]
              this.totalNatureRecette.paiement_carte += this.gareData.paiement_carte[index]
              // Remplissage des données du BarChart
              this.chartDataBarChart.labels.push(this.month[index].mois)
              this.chartDataBarChart.datasets[0].data.push(this.gareData.paiement_comptant[index])
              this.chartDataBarChart.datasets[1].data.push(this.gareData.paiement_carte[index])
              this.chartDataBarChart.datasets[0].backgroundColor.push("#4141d9")
              this.chartDataBarChart.datasets[1].backgroundColor.push("#d94144")
            }
            // Determination de la nature de recette la plus rentable
            var natureRentable="paiement_comptant"
            if (this.totalNatureRecette.paiement_comptant-this.totalNatureRecette.paiement_carte < 0) {
              natureRentable="paiement_carte"
            }
            
            // Attribution des couleurs aux Charts
            this.chartDataLineChart.datasets[0].backgroundColor.push("#FF8A80")
            this.chartDataBarChart.datasets[0].backgroundColor.push("#4141d9")
            this.chartDataBarChart.datasets[1].backgroundColor.push("#d94144")
            this.chartDataLineChart.datasets[0].label += this.gare
            this.dataLoaded=true
            // Initialisation des objets pour obtenir le mois le plus rentable et le mois le moins rentable d'une gare
            this.moisMax.mois=this.month[0].mois
            this.moisMax.montant=this.month[0].total
            this.moisMin.mois=this.month[0].mois
            this.moisMin.montant=this.month[0].total
            // initialisation du mois le plus rentable et du mois le moins rentable de la nature la plus rentable
            this.totalNatureRecette.moisMax.montant = this.gareData[natureRentable][0]
            this.totalNatureRecette.moisMax.mois = this.month[0].mois
            this.totalNatureRecette.moisMin.montant = this.gareData[natureRentable][0]
            this.totalNatureRecette.moisMin.mois = this.month[0].mois
            // Boucle pour déterminer le le mois le plus rentable et le mois le moins rentable d'une gare
            for (let index = 0; index < this.month.length; index++) {
              if (this.month[index].total > this.moisMax.montant) {//Determination du mois le plus rentable
                this.moisMax.montant=this.month[index].total
                this.moisMax.mois=this.month[index].mois
              }
              if (this.month[index].total < this.moisMin.montant) {//Determination du mois le moins rentable
                this.moisMin.montant=this.month[index].total
                this.moisMin.mois=this.month[index].mois
              }
              //Determination du mois le plus rentable et le mois le moins rentable de la nature la plus rentable
              if (this.totalNatureRecette.moisMax.montant < this.gareData[natureRentable][index]) {
                this.totalNatureRecette.moisMax.montant = this.gareData[natureRentable][index]
                this.totalNatureRecette.moisMax.mois = this.month[index].mois
              }
              if (this.totalNatureRecette.moisMin.montant > this.gareData[natureRentable][index]) {
                this.totalNatureRecette.moisMin.montant = this.gareData[natureRentable][index]
                this.totalNatureRecette.moisMin.mois = this.month[index].mois
              }
            }
            this.moisMax.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le plus rentable
              ((this.moisMax.montant*100)/this.monthTotal) 
                * 100) / 100
            this.moisMin.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le moins rentable
              ((this.moisMin.montant*100)/this.monthTotal) 
                * 100) / 100
            this.totalNatureRecette.pourcentage= natureRentable
            // console.log(this.totalNatureRecette)
          }else{
            this.gareData=null
            this.gareData = this.recettePeageG["tableau"]
            this.monthTotal = []
            this.month= [
              { mois: "Janv.", total:[0]},
              { mois: "Fev.", total:[0]},
              { mois: "Mars", total:[0]},
              { mois: "Avr.", total:[0]},
              { mois: "Mai.", total:[0]},
              { mois: "Juin", total:[0]},
              { mois: "Juil.", total:[0]},
              { mois: "Août.", total:[0]},
              { mois: "Sept.", total:[0]},
              { mois: "Oct.", total:[0]},
              { mois: "Nov.", total:[0]},
              { mois: "Dec.", total:[0]}
            ]
            this.moisMax.mois=this.month[0].mois
            this.moisMax.montant=this.month[0].total
            this.moisMin.mois=this.month[0].mois
            this.moisMin.montant=this.month[0].total
            this.chartDataLineChart={
              labels: [],
              datasets: [],
            }
            var color=[
              "#083291",
              "#910856",
              "#910818",
              "#917a08",
              "#c4b3f5",
              "#a829e3",
              "#292fe3",
              "#29b5e3",
              "#29e392",
              "#1e3b2e",
              "#3b2a1e",
              "#331e3b",
              "#806ca1",
              "#6ca19d",
              "#74a16c",
              "#a1816c",
              "#f2b8b6",
              "#b6f2b8",
              "#b3e6f5",
              "#911108",
            ]
            this.chartDataBarChart2 = {labels: [], datasets: []}

            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              this.monthTotal.push(0)
              this.chartDataLineChart.datasets.push({
                label: "Recette péage "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                borderColor:"",
                fill: false,
                hoverOffset: 4,
              })
              this.chartDataBarChart2.datasets.push(
                {
                  label: "Paiement comptant "+this.gareData[pointer].gare,
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                  // stack:'Stack '+pointer,
                },
                {
                  label: "Paiement par carte "+this.gareData[pointer].gare,
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                  // stack:'Stack '+pointer,
                }
              )
            }

            this.monthTotal.push(0)  
            
            for (let index = 0; index < this.month.length; index++) {
              for (let pointer = 0; pointer < this.gareData.length; pointer++) {
                this.month[index].total[pointer]=0
              } 
              this.month[index].total.push(0)
              this.chartDataLineChart.labels.push(this.month[index].mois)
              this.chartDataBarChart2.labels.push(this.month[index].mois)
            }

            var position=0
            var totalGareNature=[]
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              totalGareNature.push({
                gare:this.gareData[pointer].gare,
                paiement_carte:0,
                paiement_comptant:0,
              })
              for (let index = 0; index < this.month.length; index++) {
                this.month[index].total[pointer]+=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index] //+                  
                this.chartDataLineChart.datasets[pointer].data[index]=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index]
                // TOTAL de toutes les gares par mois
                this.month[index].total[this.gareData.length]+=
                  this.gareData[pointer].paiement_comptant[index] +
                  this.gareData[pointer].paiement_carte[index]
                //Total de toutes les nature par gare
                totalGareNature[pointer].paiement_carte+=this.gareData[pointer].paiement_carte[index]
                totalGareNature[pointer].paiement_comptant+=this.gareData[pointer].paiement_comptant[index]
                if (position+1<this.gareData.length) {
                  this.chartDataBarChart2.datasets[position].data[index]=this.gareData[pointer].paiement_comptant[index]
                  this.chartDataBarChart2.datasets[position+1].data[index]=this.gareData[pointer].paiement_carte[index]
                  this.chartDataBarChart2.datasets[position].backgroundColor.push(color[pointer])
                  this.chartDataBarChart2.datasets[position+1].backgroundColor.push(color[pointer])
                }
                    
                this.monthTotal[pointer] += this.month[index].total[pointer]
                //TOTAL DES TOTAUX DE TOUS LES MOIS PAR GARE
                this.monthTotal[this.gareData.length] += this.month[index].total[pointer]
                this.chartDataLineChart.datasets[pointer].backgroundColor.push(color[pointer])
              }
              this.chartDataBarChart2.datasets[position].backgroundColor.push(color[pointer])
              this.chartDataBarChart2.datasets[position+1].backgroundColor.push(color[pointer])
              this.chartDataLineChart.datasets[pointer].borderColor = color[pointer]
              position=position+2
              this.heavyDataLoaded=true
            }
            
            // Initialisation des objets pour obtenir le mois le plus rentable et le mois le moins rentable d'une gare
            var totalGareMois=[]
            this.totalAnnee=0
            for (let index = 0; index < this.month.length; index++) {
              totalGareMois.push({mois:this.month[index].mois,montant:0})
              var totalMois = 0
              for (let jindex = 0; jindex < this.month[index].total.length-1; jindex++) {
                totalMois += this.month[index].total[jindex]
                this.totalAnnee += this.month[index].total[jindex]
              }
              totalGareMois[index].montant=totalMois
            }
            // console.log(totalGareMois)
            this.moisMax.mois = totalGareMois[0].mois
            this.moisMax.montant = totalGareMois[0].montant
            this.moisMin.mois = totalGareMois[0].mois
            this.moisMin.montant = totalGareMois[0].montant
            
            for (let index = 0; index < totalGareMois.length; index++) {

              if (totalGareMois[index].montant > this.moisMax.montant) {//Determination du mois le plus rentable
                this.moisMax.montant=totalGareMois[index].montant
                this.moisMax.mois=totalGareMois[index].mois
              }
              if (totalGareMois[index].montant < this.moisMin.montant) {//Determination du mois le moins rentable
                this.moisMin.montant=totalGareMois[index].montant
                this.moisMin.mois=totalGareMois[index].mois
              }
            }
            this.moisMax.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le plus rentable
              ((this.moisMax.montant*100)/this.totalAnnee) 
                * 100) / 100
            this.moisMin.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le moins rentable
              ((this.moisMin.montant*100)/this.totalAnnee) 
                * 100) / 100

            // Determination de la gare qui rapporte le plus par carte
            this.paiement_carte={//initialisation de l'objet
              max:{
                montant: totalGareNature[0].paiement_carte,
                gare:totalGareNature[0].gare,
              },
              min:{
                montant: totalGareNature[0].paiement_carte,
                gare:totalGareNature[0].gare,
              },
            }
            this.paiement_comptant={//initialisation de l'objet
              max:{
                montant: totalGareNature[0].paiement_comptant,
                gare:totalGareNature[0].gare,
              },
              min:{
                montant: totalGareNature[0].paiement_comptant,
                gare:totalGareNature[0].gare,
              },
            }
            for (let index = 0; index < totalGareNature.length; index++) {
              
              // paiement par carte
              if (this.paiement_carte.max.montant<totalGareNature[index].paiement_carte) {
                this.paiement_carte.max.gare=totalGareNature[index].gare
                this.paiement_carte.max.montant=totalGareNature[index].paiement_carte
              }
              if (this.paiement_carte.min.montant>totalGareNature[index].paiement_carte) {
                this.paiement_carte.min.gare=totalGareNature[index].gare
                this.paiement_carte.min.montant=totalGareNature[index].paiement_carte
              }
              // paiement au comptant
              if (this.paiement_comptant.max.montant<totalGareNature[index].paiement_comptant) {
                this.paiement_comptant.max.gare=totalGareNature[index].gare
                this.paiement_comptant.max.montant=totalGareNature[index].paiement_comptant
              }
              if (this.paiement_comptant.min.montant>totalGareNature[index].paiement_comptant) {
                this.paiement_comptant.min.gare=totalGareNature[index].gare
                this.paiement_comptant.min.montant=totalGareNature[index].paiement_comptant
              }               
              
            }
          }
        } else {
          this.gareData=null
          this.gareData = this.recettePeageG["tableau"]
          this.monthTotal = []
          this.month= [
            { mois: "Janv.", total:[0]},
            { mois: "Fev.", total:[0]},
            { mois: "Mars", total:[0]},
            { mois: "Avr.", total:[0]},
            { mois: "Mai.", total:[0]},
            { mois: "Juin", total:[0]},
            { mois: "Juil.", total:[0]},
            { mois: "Août.", total:[0]},
            { mois: "Sept.", total:[0]},
            { mois: "Oct.", total:[0]},
            { mois: "Nov.", total:[0]},
            { mois: "Dec.", total:[0]}
          ]
          this.moisMax.mois=this.month[0].mois
          this.moisMax.montant=this.month[0].total
          this.moisMin.mois=this.month[0].mois
          this.moisMin.montant=this.month[0].total
          this.chartDataLineChart={
            labels: [],
            datasets: [],
          }
          color=[
            "#083291",
            "#910856",
            "#910818",
            "#917a08",
            "#c4b3f5",
            "#a829e3",
            "#292fe3",
            "#29b5e3",
            "#29e392",
            "#1e3b2e",
            "#3b2a1e",
            "#331e3b",
            "#806ca1",
            "#6ca19d",
            "#74a16c",
            "#a1816c",
            "#f2b8b6",
            "#b6f2b8",
            "#b3e6f5",
            "#911108",
          ]
          this.chartDataBarChart2 = {labels: [], datasets: []}

          for (let pointer = 0; pointer < this.gareData.length; pointer++) {
            this.monthTotal.push(0)
            this.chartDataLineChart.datasets.push({
              label: "Recette péage "+this.gareData[pointer].gare,
              data: [],
              backgroundColor: [],
              borderColor:"",
              fill: false,
              hoverOffset: 4,
            })
            this.chartDataBarChart2.datasets.push(
              {
                label: "Paiement comptant "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                hoverOffset: 4,
                // stack:'Stack '+pointer,
              },
              {
                label: "Paiement par carte "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                hoverOffset: 4,
                // stack:'Stack '+pointer,
              }
            )
          }

          this.monthTotal.push(0)  
            
          for (let index = 0; index < this.month.length; index++) {
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              this.month[index].total[pointer]=0
            } 
            this.month[index].total.push(0)
            this.chartDataLineChart.labels.push(this.month[index].mois)
            this.chartDataBarChart2.labels.push(this.month[index].mois)
          }

          position=0
          totalGareNature=[]
          for (let pointer = 0; pointer < this.gareData.length; pointer++) {
            totalGareNature.push({
              gare:this.gareData[pointer].gare,
              paiement_carte:0,
              paiement_comptant:0,
            })
            for (let index = 0; index < this.month.length; index++) {
              this.month[index].total[pointer]+=
                this.gareData[pointer].paiement_comptant[index] +
                this.gareData[pointer].paiement_carte[index] //+                  
              this.chartDataLineChart.datasets[pointer].data[index]=
                this.gareData[pointer].paiement_comptant[index] +
                this.gareData[pointer].paiement_carte[index]
              // TOTAL de toutes les gares par mois
              this.month[index].total[this.gareData.length]+=
                this.gareData[pointer].paiement_comptant[index] +
                this.gareData[pointer].paiement_carte[index]
              //Total de toutes les nature par gare
              totalGareNature[pointer].paiement_carte+=this.gareData[pointer].paiement_carte[index]
              totalGareNature[pointer].paiement_comptant+=this.gareData[pointer].paiement_comptant[index]
              if (position+1<this.gareData.length) {
                this.chartDataBarChart2.datasets[position].data[index]=this.gareData[pointer].paiement_comptant[index]
                this.chartDataBarChart2.datasets[position+1].data[index]=this.gareData[pointer].paiement_carte[index]
                this.chartDataBarChart2.datasets[position].backgroundColor.push(color[pointer])
                this.chartDataBarChart2.datasets[position+1].backgroundColor.push(color[pointer])
              }
                    
              this.monthTotal[pointer] += this.month[index].total[pointer]
              //TOTAL DES TOTAUX DE TOUS LES MOIS PAR GARE
              this.monthTotal[this.gareData.length] += this.month[index].total[pointer]
              this.chartDataLineChart.datasets[pointer].backgroundColor.push(color[pointer])
            }
            this.chartDataBarChart2.datasets[position].backgroundColor.push(color[pointer])
            this.chartDataBarChart2.datasets[position+1].backgroundColor.push(color[pointer])
            this.chartDataLineChart.datasets[pointer].borderColor = color[pointer]
            position=position+2
            this.heavyDataLoaded=true
          }
            
          // Initialisation des objets pour obtenir le mois le plus rentable et le mois le moins rentable d'une gare
          totalGareMois=[]
          this.totalAnnee=0
          for (let index = 0; index < this.month.length; index++) {
            totalGareMois.push({mois:this.month[index].mois,montant:0})
            totalMois = 0
            for (let jindex = 0; jindex < this.month[index].total.length-1; jindex++) {
              totalMois += this.month[index].total[jindex]
              this.totalAnnee += this.month[index].total[jindex]
            }
            totalGareMois[index].montant=totalMois
          }
          // console.log(totalGareMois)
          this.moisMax.mois = totalGareMois[0].mois
          this.moisMax.montant = totalGareMois[0].montant
          this.moisMin.mois = totalGareMois[0].mois
          this.moisMin.montant = totalGareMois[0].montant
            
          for (let index = 0; index < totalGareMois.length; index++) {

            if (totalGareMois[index].montant > this.moisMax.montant) {//Determination du mois le plus rentable
              this.moisMax.montant=totalGareMois[index].montant
              this.moisMax.mois=totalGareMois[index].mois
            }
            if (totalGareMois[index].montant < this.moisMin.montant) {//Determination du mois le moins rentable
              this.moisMin.montant=totalGareMois[index].montant
              this.moisMin.mois=totalGareMois[index].mois
            }
          }
          this.moisMax.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le plus rentable
            ((this.moisMax.montant*100)/this.totalAnnee) 
              * 100) / 100
          this.moisMin.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le moins rentable
            ((this.moisMin.montant*100)/this.totalAnnee) 
              * 100) / 100

          // Determination de la gare qui rapporte le plus par carte
          this.paiement_carte={//initialisation de l'objet
            max:{
              montant: totalGareNature[0].paiement_carte,
              gare:totalGareNature[0].gare,
            },
            min:{
              montant: totalGareNature[0].paiement_carte,
              gare:totalGareNature[0].gare,
            },
          }
          this.paiement_comptant={//initialisation de l'objet
            max:{
              montant: totalGareNature[0].paiement_comptant,
              gare:totalGareNature[0].gare,
            },
            min:{
              montant: totalGareNature[0].paiement_comptant,
              gare:totalGareNature[0].gare,
            },
          }
          for (let index = 0; index < totalGareNature.length; index++) {
              
            // paiement par carte
            if (this.paiement_carte.max.montant<totalGareNature[index].paiement_carte) {
              this.paiement_carte.max.gare=totalGareNature[index].gare
              this.paiement_carte.max.montant=totalGareNature[index].paiement_carte
            }
            if (this.paiement_carte.min.montant>totalGareNature[index].paiement_carte) {
              this.paiement_carte.min.gare=totalGareNature[index].gare
              this.paiement_carte.min.montant=totalGareNature[index].paiement_carte
            }
            // paiement au comptant
            if (this.paiement_comptant.max.montant<totalGareNature[index].paiement_comptant) {
              this.paiement_comptant.max.gare=totalGareNature[index].gare
              this.paiement_comptant.max.montant=totalGareNature[index].paiement_comptant
            }
            if (this.paiement_comptant.min.montant>totalGareNature[index].paiement_comptant) {
              this.paiement_comptant.min.gare=totalGareNature[index].gare
              this.paiement_comptant.min.montant=totalGareNature[index].paiement_comptant
            }               
              
          }
        }
      }
    },

    recettePeage() {
      if (this.recettePeage["tableau"]) {
        this.yearData = this.recettePeage
        this.total = {
          recette:0,
          direct:0,
          carte:0,
        }
        this.listGare=[]

        this.gareMax.montant = this.yearData.tableau[0].recettes
        this.gareMax.gare = this.yearData.tableau[0].gare
        this.gareMin.montant = this.yearData.tableau[0].recettes
        this.gareMin.gare = this.yearData.tableau[0].gare
        // console.log(this.gareMax)
        // console.log(this.gareMin)
        this.yearData.tableau.forEach((recette) => {
          this.total.recette += Number(recette.recettes)
          this.total.direct += Number(recette.paiement_direct)
          this.total.carte += Number(recette.paiement_carte)
          this.listGare.push(recette.gare)
          if (recette.recettes > this.gareMax.montant) {
            this.gareMax.montant = Number(recette.recettes)
            this.gareMax.gare = recette.gare
          }
          if (recette.recettes < this.gareMin.montant) {
            this.gareMin.montant = Number(recette.recettes)
            this.gareMin.gare = recette.gare
          }
        })
        this.gareRanking=[]
        for (let index = 0; index < this.yearData.tableau.length; index++) {
          this.gareRanking.push({rang:index,recette:this.yearData.tableau[index].recettes,gare:this.yearData.tableau[index].gare})
        }
        this.gareRanking = this.gareRanking.sort((a,b)=>b.recette - a.recette)
        for (let index = 0; index < this.gareRanking.length; index++) {
          if (this.gare == this.gareRanking[index].gare) {
            this.garePosition={rang: index+1,gare:this.gare,recette:this.gareRanking[index].recette,pourcentage:0}
          }
        }
        this.listGare.push("CONSOLIDEE")
        this.listGare.push("TOUTES")
        if (this.total.recette - this.yearData.budget_cible > 0) {
          this.color = "good-news"
        } else {
          this.color = "bad-news"
        }
        if (window.innerWidth > 1400) {
          this.width = "col-md-4 mx-auto"
        }
        this.addColor(this.yearData.graphique.length)

        this.yearData.graphique.forEach((graphique) => {
          if (this.gare==graphique.gare) {
            this.garePosition.pourcentage = Math.round(Number(graphique.pourcentage) * 100) / 100
          }
          if (graphique.gare == this.gareMax.gare) {
            this.gareMax.pourcentage = Math.round(Number(graphique.pourcentage) * 100) / 100
          }
          if (graphique.gare == this.gareMin.gare) {
            this.gareMin.pourcentage = Math.round(Number(graphique.pourcentage) * 100) / 100
          }
          this.chartDataPieChart.datasets[0].data.push(
            Math.round(Number(graphique.pourcentage) * 100) / 100
          )
          this.chartDataPieChart.labels.push(
            graphique.gare +
              " (" +
              Math.round(Number(graphique.pourcentage) * 100) / 100 +
              "%)"
          )
        })
        
      }
    },
    // extYear() {
    //   if (this.extYear) {
    //     this.setRecettePeage("")
        
    //     this.year = this.extYear
    //     this.yearData = null
    //     this.RecettePeage({ annee: this.year })


    //     this.chartDataPieChart.datasets[0].data = []
    //     this.chartDataPieChart.labels = []
    //     this.chartDataPieChart.datasets[0].backgroundColor = []
    //   }
    // },
    // changeYear(){
    //   if (this.year) {
    //     this.redevances=null
    //     this.setRecettePeage("")
    //     this.chartDataPieChart.datasets[0].data = []
    //     this.chartDataPieChart.labels=[]
    //     this.chartDataPieChart.datasets[0].backgroundColor=[]
    //     this.RecettePeage({annee:this.year})
    //   }
    // }
  },
  computed: {
    ...mapGetters(["arRecetteG", "arRecette", "recettePeage", "recettePeageG"]),
  },
  methods: {
    ...mapActions(["RecettePeage", "RecettePeageG"]),
    ...mapMutations(["setRecettePeage", "setRecettePeageG"]),
    changeGare(gare) {
      this.redevances=null
      this.axe=''
      this.gare=''
      this.heavyDataLoaded=false
      this.dataLoaded=false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setRecettePeage("")
      this.chartDataPieChart.datasets[0].data = []
      this.chartDataPieChart.labels=[]
      this.chartDataPieChart.datasets[0].backgroundColor=[]
      this.chartDataLineChart.datasets = [{
        label: "Recette péage ",
        data: [],
        backgroundColor: [],
        fill: true,
        hoverOffset: 4,
      }]
      this.chartDataLineChart.labels=[]
      this.chartDataBarChart= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.chartDataBarChart2= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.yearData=null
      this.gareData=null
      this.gare=gare
      this.RecettePeage(this.year)
      this.RecettePeageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, gare: this.gare })
    },
    changeAxe(axe) {
      this.redevances=null
      this.gare=''
      this.axe=''
      this.heavyDataLoaded=false
      this.dataLoaded=false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setRecettePeage("")
      this.chartDataPieChart.datasets[0].data = []
      this.chartDataPieChart.labels=[]
      this.chartDataPieChart.datasets[0].backgroundColor=[]
      this.chartDataLineChart.datasets = [{
        label: "Recette péage ",
        data: [],
        backgroundColor: [],
        fill: true,
        hoverOffset: 4,
      }]
      this.chartDataBarChart= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.chartDataBarChart2= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.chartDataLineChart.labels=[]
      this.yearData=null
      this.gareData=null
      this.axe=axe
      this.RecettePeage(this.year)
      this.RecettePeageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, axe: this.axe })
    },
    changeYear() {
      this.redevances=null
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setRecettePeage("")
      this.chartDataPieChart.datasets[0].data = []
      this.chartDataPieChart.labels=[]
      this.chartDataPieChart.datasets[0].backgroundColor=[]
      this.chartDataLineChart.datasets[0].data = []
      this.chartDataLineChart.datasets = [{
        label: "Recette péage ",
        data: [],
        backgroundColor: [],
        hoverOffset: 4,
      }]
      this.setRecettePeageG("")
      this.chartDataLineChart.labels=[]
      this.chartDataBarChart = {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.heavyDataLoaded=false
      this.dataLoaded=false
      this.yearData=null
      this.gareData=null
      this.RecettePeage(this.year)
      if (this.gare!=null && this.gare!='') {
        this.RecettePeageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, gare: this.gare })
      }else{
        this.RecettePeageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, axe: this.axe })
      }
    },

    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    addColor(long) {
      for (let i = 0; i < long; i++) {
        this.chartDataPieChart.datasets[0].backgroundColor.push(
          this.tableauCouleurs[i]
        )
      }
    },
  },
}
</script>

  