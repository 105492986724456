<script>
import { Line as LineGenerator} from 'vue-chartjs'

export default {
  extends: LineGenerator,
  name: 'LineChart',
  props: ['chartdata', 'options'],
  mounted () {
    console.log(this.chartdata)
    this.renderChart(this.chartdata, this.options)
  },
}
</script>

<style>
  
</style>